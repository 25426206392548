import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Segmentation`}</h1>
    <p>{`Create, configure and delete segments and user profiles in Engagement Studio.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b81d4201955150d03bb326b73bb1494b/d90ad/es-segmentation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCElEQVQoz23RS08TURjG8fkcmuDCcLPlUmpJgWJl4cKN7gyXslAhUHpBQWEKoaG27IwILYK20XYGKDOdOXOmFMO3+5sZCHHB4pecPIv3vOc5SnTNJr4hGUpd0J8SRF4lCcVeEErsMPEuT3xxl5H5bfrebBCY3iQwcyM4u8mAZ+7GcEJlKKGihLMtvKEDyXOCGUn/VILHg1Fer30lWzpida9CsnDI0u4By4UDUsVD0qXynczef0pllLE1m8gHi8DSGYOrLt2xaR70DJM/aGBcSEyzhXQkrvS4XP/t3OPq7qzEPgnCWdMfOJB16Xk2w8O+EXYrDa6vOkjXpd1u+6SU2ELQalk43gXtNoZpomk6uq7TbDZRvO3G121G0hf+k/uez/EoGGHrWw3TMGlZwmfZAsdx/KGWZWEYBqZpIoTAtm3q9bqfKRPrguhHm+Dy+d2GXYGnbO/XaGin/PqtUf2jUavrnJ6dcd5souk6JycnHB8fU280/KyhaX7udzj5WTC00vQ37I3P0hUIUzyqc+mI2w4FHVdyKR0uXUnbEYiWiW0auML2s84txfthv8eMwZO0Q+jlAoPjU8zv7FMsV8l//0mhXKVQqfKlUqXoOapR/FGjdA/F68/jbeg9uXtylt5QlNHFPOG3KpH3KqMLKtFFlbEllfHlHLFkjthKjslUjmfpHPH0FvHMjX/K88OHmeL2tgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b81d4201955150d03bb326b73bb1494b/e93cc/es-segmentation.webp 300w", "/static/b81d4201955150d03bb326b73bb1494b/b0544/es-segmentation.webp 600w", "/static/b81d4201955150d03bb326b73bb1494b/68fc1/es-segmentation.webp 1200w", "/static/b81d4201955150d03bb326b73bb1494b/9defe/es-segmentation.webp 1742w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b81d4201955150d03bb326b73bb1494b/eed55/es-segmentation.png 300w", "/static/b81d4201955150d03bb326b73bb1494b/7491f/es-segmentation.png 600w", "/static/b81d4201955150d03bb326b73bb1494b/8537d/es-segmentation.png 1200w", "/static/b81d4201955150d03bb326b73bb1494b/d90ad/es-segmentation.png 1742w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b81d4201955150d03bb326b73bb1494b/8537d/es-segmentation.png",
              "alt": "es segmentation",
              "title": "es segmentation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      